@mixin xs {
    @media (max-width: 576px) {
        @content;
    }
    // /* Landscape */
    // @media screen and (orientation: landscape/ portrait) {
    //     /* Landscape styles here */
    // }
}

@mixin sm {
    @media (min-width: 577px) {
        @content;
    }
}
@mixin md {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin lg {
    @media (min-width: 992px) {
        @content;
    }
}
@mixin xl {
    @media (min-width: 1200px) {
        @content;
    }
}
