* {
    margin: 0;
    padding: 0;
}
.content-p {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    .form-content {
        width: 400px;
    }
}
