input,
button {
    border: none;
    outline: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
ul,
li {
    list-style: none;
}
img {
    display: block;
}
